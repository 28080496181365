import { useState } from "react";
import LightTheme from "./src/components/LightTheme";
import ThemeContext from "./src/contexts/ThemeContext";

const Provider = ({ children }) => {
  const [theme, setTheme] = useState(LightTheme);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const ProviderWrapper = ({ element }) => <Provider>{element}</Provider>;

export default ProviderWrapper;
