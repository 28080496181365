const Colours = {
  white: "#EFEFEF",
  red: "#FF4949",
  darkblack: "#131516",
  black: "#353b3c",
  yellow: "#ffc600",
  grey: "#E0E0E0",
  darkgrey: "#495057",
  lightblue: "#9ed0e6",
  blue: "#235789",
  orange: "#F79824",
  gold: "#F5C800",
};

export default Colours;
