import Colours from "./Colours";

const LightTheme = {
  ...Colours,
  bg: Colours.grey,
  navbarBg: Colours.white,
  footerBg: Colours.black,
  primary: Colours.white,
  secondary: Colours.black,
  highlight: Colours.blue,
  other: Colours.blue,
  disabled: Colours.darkgrey,
  pop: Colours.gold,
};

export default LightTheme;
